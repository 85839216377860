<template>
   <div id="addBox">
      <h3>新增患者</h3>
      <p>(注：带红点为必填项)</p>
      <div>
         <el-form :model="list">

            <el-form-item label="患者姓名">
               <el-badge is-dot>
                  <el-input v-model="list.CNAME"
                            placeholder="患者姓名"
                            autocomplete="off"
                            label-width="80px"></el-input>
               </el-badge>
            </el-form-item>
            <el-form-item label="患者年龄">
               <el-badge is-dot>
                  <el-input v-model="list.Age"
                            placeholder="患者年龄"
                            autocomplete="off"
                            label-width="80px"></el-input>
               </el-badge>
            </el-form-item>
            <el-form-item label="患者性别">
               <el-badge is-dot>
                  <el-select v-model="list.Sex">
                     <el-option value="男"></el-option>
                     <el-option value="女"></el-option>
                  </el-select>
               </el-badge>
            </el-form-item>
            <el-form-item label="身份证号">

               <el-input v-model="list.CID"
                         type="text"
                         name="firstname"
                         maxlength="19"
                         label-width="80px"></el-input>

            </el-form-item>

            <el-form-item label="就诊卡号">
               <el-badge is-dot>
                  <el-input v-model="list.HospitalID"
                            type="text"
                            name="firstname"
                            maxlength="18"
                            placeholder="就诊卡号"
                            autocomplete="off"
                            label-width="80px"></el-input>
               </el-badge>
            </el-form-item>
            <el-form-item label="既往病史">

               <el-input v-model="list.History"
                         placeholder="既往病史"
                         autocomplete="off"
                         label-width="80px"></el-input>
               </el-badge>
            </el-form-item>

            <el-form-item label="联系电话">
               <el-badge is-dot>
                  <el-input v-model="list.phone"
                            type="text"
                            name="firstname"
                            maxlength="11"
                            placeholder="联系电话"
                            autocomplete="off"
                            label-width="80px"></el-input>
               </el-badge>
            </el-form-item>
            <el-form-item label="患者地址">

               <el-input v-model="list.address"
                         placeholder="患者地址"
                         autocomplete="off"
                         label-width="80px"></el-input>
               </el-badge>
            </el-form-item>
            <el-form-item>
               <el-button type="primary"
                          @click="adds">提交</el-button>

            </el-form-item>
            <!-- </el-form-item> -->
         </el-form>
      </div>
   </div>
</template>

<script>
import { Message } from "element-ui";
import { createNamespacedHelpers } from "vuex";
import api from "../http/api";
import axios from "../http/axios";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
   "patient"
);
export default {
   data () {
      return {
         labelPosition: "right",
         list: {
            CID: "",
            CNAME: "",
            Age: "",
            Sex: "",
            HospitalID: "",
            phone: "",
            History: NaN,
            address: NaN,
         },
      };
   },

   methods: {
      // ...mapActions(["addPatient"]),
      async adds () {
         const data = await axios({
            url: `/Patient/addPatient?CID=${this.list.CID}&CNAME=${this.list.CNAME}&Age=${this.list.Age}&Sex=${this.list.Sex}&HospitalID=${this.list.HospitalID}&phone=${this.list.phone}&History=${this.list.History}&address=${this.list.address}`,
            method: "post",
         });
         if (data.data.msg == "请求处理正常") {
            Message.success("新增成功");
            this.list = {
               CID: '', CNAME: '', Age: '', Sex: '', HospitalID: '', phone: '', History: NaN,
               address: NaN,
            };
            // this.list = "";
            // this.$router.push("/System/ReportList");
         } else {
            Message.warning("新增失败");
         }
      },

   },
   beforeRouteLeave (to, from, next) {
      if (this.list.name) {
         const isLeave = confirm("您还有数据没有提交，确定要离开吗？");
         if (isLeave) {
            next();
         }
      } else {
         next();
      }
   },
};
</script>

<style lang='scss' scoped>
.el-dropdown-link {
   cursor: pointer;
   color: #409eff;
}
.el-icon-arrow-down {
   font-size: 12px;
}
#addBox {
   width: 400px;
   margin: auto;
   text-align: center;
}
.el-form-item {
   width: 300px;
   margin: auto;
   display: flex;
   justify-content: space-evenly;
   margin: 10px;
   .el-select {
      width: 200px;
   }
}
p {
   color: red;
   font-size: 12px;
}
</style>
